import React, {Component} from 'react';
import Menu from '../organisms/Menu';
import LogoSection from '../molecules/LogoSection';
import HeaderLogo from './../../images/LogoRectangular.png';

export default class Header extends Component {
    constructor(props) {
        super(props);
    }
    render () {
        return (
            <nav className="fh5co-nav" role="navigation">
                <div className="container-fluid">
                    <Menu activeOptionPosition={this.props.activeOptionPosition} />
                    <LogoSection divClassName="col-xs-12 text-center menu-2" image={HeaderLogo} imageClassName="img-responsive" imageStyle={{height: 200, margin: 'auto'}} />
                </div>
            </nav>
        )
    }
}