import React from 'react';

type Props = {
    children?: React.Node
}

const DesignsSection = (props: Props) => {
    return (
        <div className="row">
            {props.children}
        </div>
    )
}

export default DesignsSection;