import React from 'react';

type Props = {
    children?: React.Node
}

const FeaturedSliderBox = (props: Props) => {
    return (
        <div className="col-md-4">
            {props.children}
        </div>
    );
}

export default FeaturedSliderBox;