import './App.css';
import './styles/animate.css';
import './styles/icomoon.css';
import './styles/bootstrap.css';
import './styles/magnific-popup.css';
import './styles/flexslider.css';
import './styles/style.css';
import './styles/bootstrap-icons.css';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './components/pages/Home';
import Gallery from './components/pages/Gallery';
import DeliveryZones from './components/pages/DeliveryZones';
import Stories from './components/pages/Stories';

function App() {
  return (
    <Router>
      <Routes>
          <Route
              exact
              path="/"
              element={<Home />}
          />
          <Route
              path="/galeria"
              element={<Gallery />}
          />
          <Route
              path="/lugares"
              element={<DeliveryZones />}
          />
          <Route
              path="/cuentos"
              element={<Stories />}
          />
          <Route
              path="*"
              element={<Navigate to="/" />}
          />
      </Routes>
    </Router>
  );
}

export default App;
