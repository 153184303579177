import React from 'react';

const FeaturedBox = ({ Image, textTop, title, textBottom }) => {
    return (
        <a className="featured text-center animate-box featured-box-sm-h" style={{ backgroundImage: "url(" + Image + ")", backgroundPosition: "center"}}>
            <div className="desc">
                <span className="date">{textTop}</span>
                <h3>{title}</h3>
                <span className="category">{textBottom}</span>
            </div>
        </a>
    );
}

export default FeaturedBox;