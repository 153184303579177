import React, {Component} from 'react';
import RowHeaderBox from '../molecules/RowHeaderBox';
import SocialNetworkBox from '../molecules/SocialNetworkBox';

import FacebookImage from './../../images/SocialNetworks/facebookLogo.png';
import InstagramImage from './../../images/SocialNetworks/instagramLogoPNG.png';
import TiktokImage from './../../images/SocialNetworks/tiktokLogo.png';

export default class SocialNetworks extends Component {
    render () {
        return (
            <div id="fh5co-blog-popular">
                <div className="container">
                    <RowHeaderBox title="Nuestras redes" />
                </div>
                <div className="row d-flex justify-content-center social-network-row-mb">
                    <SocialNetworkBox className="col-xs-3 col-sm-3 col-md-3 animate-box" Image={FacebookImage} imageClassName="social-network-box-image-1 img-center" animateEffect="fadeIn" imageLink="https://www.facebook.com/LanuditoPet?mibextid=ZbWKwL" />
                    <SocialNetworkBox className="col-xs-3 col-sm-3 col-md-3 animate-box" Image={InstagramImage} imageClassName="social-network-box-image-1 img-center" animateEffect="fadeIn" imageLink="https://www.instagram.com/lanuditopet?igshid=YTQwZjQ0NmI0OA==" />
                    <SocialNetworkBox className="col-xs-3 col-sm-3 col-md-3 animate-box" Image={TiktokImage} imageClassName="social-network-box-image-2 img-center" animateEffect="fadeIn" imageLink="https://www.tiktok.com/@lanuditopet?_t=8iBj9OGCToL&_r=1" />
                </div>
            </div>
        )
    }
}