import React, {Component} from 'react';
import RowHeaderBox from '../molecules/RowHeaderBox';

import ImageSlider2 from '../../images/Slider/sliderImage2.jpg';
import ImageDog1 from '../../images/Gallery/imgCuadradaPerro1.jpg';
import ImageDog3 from '../../images/Gallery/imgPerroGrandeYChico.jpeg';
import ImageCat1 from '../../images/Gallery/imgGatoGrande.jpg';
import ImageDog4 from '../../images/Gallery/imgPerroLargo.jpg';
import ImageDog2 from '../../images/Gallery/imgPerroLlaveroLargo.jpg';
import ImageDog5 from '../../images/Gallery/imgPerroGrande2.jpg';
import ImageDog8 from '../../images/Gallery/imgPerroChicoLargo.jpg';
import ImageDog12 from '../../images/Gallery/imgPerroGrande4.jpg';
import ImageDog11 from '../../images/Gallery/imgPerroPequnioLargo.jpg';
import ImageSpecial8 from '../../images/Gallery/imgEspecialCuadrado3.jpg';
import ImageDog17 from '../../images/Gallery/imgPerroCuadrado6.jpg';
import ImageDog18 from '../../images/Gallery/imgPerroGrande7.jpg';
import ImageDog20 from '../../images/Gallery/imgPerroCuadrado5.jpg';
import ImageDog19 from '../../images/Gallery/imgPerrosCuadrado3.jpg';
import ImageSpecial7 from '../../images/Gallery/specialMapaches.jpeg';
import ImageDog16 from '../../images/Gallery/imgTresPerrosCuadrada.jpeg';
import ImageDog14 from '../../images/Gallery/imgEspecialLargo4.jpg';
import ImageCat2 from '../../images/Gallery/imgGatoGrande4.jpeg';
import ImageCat3 from '../../images/Gallery/imgGatoGrande5.jpeg';
import ImageDog15 from '../../images/Gallery/imgPerroGrande14.jpg';
import ImageSpecial6 from '../../images/Gallery/imgEspecialLargo2.jpg';
import ImageDog13 from '../../images/Gallery/imgPerroLargo3.jpg';
import ImageSpecial5 from '../../images/Gallery/imgEspecialLargo3.jpg';
import ImageDog10 from '../../images/Gallery/imgPerroGrande10.jpg';
import ImageDog9 from '../../images/Gallery/imgPerroGrande5.jpg';
import ImageSpecial4 from '../../images/Gallery/imgEspecialCuadrado.jpg';
import ImageDog6 from '../../images/Gallery/imgPerroGrande3.jpg';
import ImageSpecial3 from '../../images/Gallery/imgEspecialLargo.jpg';
import ImageSpecial2 from '../../images/Gallery/imgEspecialGatoLargo.jpg';
import ImageSpecial1 from '../../images/Gallery/imgEspecialCuadrado2.jpg';
import ImageDog7 from '../../images/Gallery/imgPerroCuadrado2.jpg';

export default class GalleryCollage extends Component {
    render () {
        return (
            <div id="fh5co-content" className="fh5co-no-pd-top">
                <div className="container">
                    <RowHeaderBox title="Galería" />
                    <div className="row">
				        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="row g-3 gallery-collage-area">
                                    <div className="col-6 text-end gallery-collage-align-right">
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.1s" src={ImageCat1} alt="" />
                                        <img className="img-fluid bg-white w-50 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.2s" src={ImageSpecial1} alt="" />
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.1s" src={ImageDog6} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog9} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog7} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog10} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog17} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog18} alt="" />
                                        
                                    </div>
                                    <div className="col-6 gallery-collage-align-flex">
                                        <img className="img-fluid bg-white w-50 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.3s" src={ImageDog1} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog4} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSpecial3} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSpecial7} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog13} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog16} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageCat2} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSpecial8} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row g-3 gallery-collage-area">
                                    <div className="col-6 text-end gallery-collage-align-right">
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.1s" src={ImageSpecial2} alt="" />
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.1s" src={ImageDog5} alt="" />
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.1s" src={ImageDog8} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSpecial5} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSpecial6} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog15} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSlider2} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog19} alt="" />
                                    </div>
                                    <div className="col-6 gallery-collage-align-flex">
                                        <img className="img-fluid bg-white w-50 mb-3 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.3s" src={ImageDog3} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog2} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageSpecial4} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog12} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog11} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog14} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageCat3} alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn gallery-collage-image-wd-100" data-wow-delay="0.4s" src={ImageDog20} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        )
    }
}