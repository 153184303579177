import React, {Component} from 'react';
import Header from '../templates/Header';
import Footer from '../templates/Footer';
import StoriesBooks from '../templates/StoriesBooks'

export default class Stories extends Component {
    componentDidMount () {
        window.executeHome();
    }

    render () {
        return (
            <div id="page">
                <Header activeOptionPosition="Stories" />
                <StoriesBooks />
                <Footer />
            </div>
        )
    }
}