import React, {Component} from 'react';
import Header from '../templates/Header';
import FlexSlider from '../templates/FlexSlider';
import AboutUs from '../templates/AboutUs';
import Designs from '../templates/Designs';
import SocialNetworks from '../templates/SocialNetworks';
import Footer from '../templates/Footer';

export default class Home extends Component {
    componentDidMount () {
        window.executeHome();
    }
    render () {
        return (
            <div id="page">
                <Header activeOptionPosition="Home" />
                <FlexSlider />
                <AboutUs />
                <Designs />
                <SocialNetworks />
                <Footer />
            </div>
        )
    }
}