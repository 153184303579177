import React, {Component} from 'react';
import RowHeaderBox from '../molecules/RowHeaderBox';
import DesignsSection from '../organisms/DesignsSection';
import DesignBox from '../molecules/DesignBox';

import CategoryFullBodyImage from './../../images/Categories/CuerpoCompleto.jpg';
import CategorySpecialsImage from './../../images/Categories/specialMapaches.jpeg';
import CategoryKeyChainImage from './../../images/Categories/Llaveros.jpg';
import CategoryFrameImage from './../../images/Categories/Cuadro.jpg';

export default class Designs extends Component {
    render () {
        return (
            <div id="fh5co-blog-popular">
                <div className="container">
                    <RowHeaderBox title="Diseños" />
                    <DesignsSection>
                        <DesignBox Image={CategoryFullBodyImage} imageClassName="img-responsive design-box-mh" title="Cuerpo completo" />
                        <DesignBox Image={CategorySpecialsImage} imageClassName="img-responsive design-box-mh" title="Especiales" />
                        <DesignBox Image={CategoryKeyChainImage} imageClassName="img-responsive design-box-mh" title="Llaveros" />
                        <DesignBox Image={CategoryFrameImage} imageClassName="img-responsive design-box-mh" title="Marco" />
                    </DesignsSection>
                </div>
            </div>
        )
    }
}