import React from 'react';

const RowHeaderBox = ({ title }) => {
    return (
        <div className="row animate-box">
            <div className="col-md-12 col-md-offset-0 text-center fh5co-heading">
                <h2><span>{title}</span></h2>
            </div>
        </div>
    )
}

export default RowHeaderBox;