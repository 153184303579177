import React from 'react';
import SocialIconsSection from './SocialIconsSection';
import SocialIcon from '../atoms/SocialIcon';
import VideoLanuditopet from '../../videos/LanuditopetVideo.mp4';
import VideoLanuditopetPoster from '../../images/LanuditopetVideoPortada.jpeg';

const AboutUsStaffSection = ({ Image, title, strongTitle, text }) => {
    return (
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="fh5co-staff">
                    <img src={Image} alt="" />
                    <h3 className="fw-501">{title}</h3>
                    <p>{strongTitle}</p>
                    <p>{text}</p>
                    <SocialIconsSection>
                        <SocialIcon className="bi bi-facebook" href="https://www.facebook.com/LanuditoPet?mibextid=ZbWKwL" />
                        <SocialIcon className="bi bi-instagram" href="https://www.instagram.com/lanuditopet?igshid=YTQwZjQ0NmI0OA==" />
                        <SocialIcon className="bi bi-tiktok" href="https://www.tiktok.com/@lanuditopet?_t=8iBj9OGCToL&_r=1" />
                    </SocialIconsSection>
                </div>
            </div>
            <div className="home-video">
                <video controls src={VideoLanuditopet} poster={VideoLanuditopetPoster} width="100%">
                    Disculpa, tu navegador no soporta el video compartido, pero no te preocupes, puedes
                    <a href="https://fb.watch/qG5LVfHqrS/?mibextid=Nif5oz"> mirarlo desde facebook.</a>
                </video>
            </div>
        </div>
    );
}

export default AboutUsStaffSection;