import React from 'react';

const SocialNetworkBox = ({ className, Image, imageClassName, animateEffect, imageLink }) => {
    return (
        <div className={className} data-animate-effect={animateEffect}>
            <a href={imageLink} target="blank_"><img src={Image} alt='' className={imageClassName} /></a>
        </div>
    )
}

export default SocialNetworkBox;