import React, {Component} from 'react';
import Header from '../templates/Header';
import GalleryCollage from '../templates/GalleryCollage';
import Footer from '../templates/Footer';

export default class Gallery extends Component {
    componentDidMount () {
        window.executeHome();
    }

    render () {
        return (
            <div id="page">
                <Header activeOptionPosition="Gallery" />
                <GalleryCollage />
                <Footer />
            </div>
        )
    }
}