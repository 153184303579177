import React from 'react';

const LogoSection = ({ divClassName, image, imageClassName, imageStyle }) => {
    return (
        <div className="row">
            <div className={divClassName}>
                <img className={imageClassName} src={image} alt="" style={imageStyle} />
            </div>
        </div>
    )
}

export default LogoSection;