import React, {Component} from 'react';
import AnimateSliderBox from '../organisms/AnimateBoxSlider';
import AnimateBox from '../molecules/AnimateBox';
import FeaturedSliderBox from '../organisms/FeaturedBoxSlider';
import FeaturedBox from '../molecules/FeaturedBox';

import AnimateImage1 from './../../images/Slider/sliderImage1.jpg';
import AnimateImage2 from './../../images/Slider/sliderImage2.jpg';
import AnimateImage3 from './../../images/Slider/sliderImage3.jpg';

import FeaturedImage1 from './../../images/Slider/featuredImage1.jpeg';
import FeaturedImage2 from './../../images/Slider/featuredImage2.jpg';


export default class FlexSlider extends Component {
    render () {
        return (
            <aside id="fh5co-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <AnimateSliderBox>
                                <AnimateBox Image={AnimateImage1} title="Bienvenidos a Lanuditopet" subtitle="Réplicas de mascotas personalizadas" />
                                <AnimateBox Image={AnimateImage2} title="Materiales de la mejor calidad" subtitle="Hechos a mano" />
                                <AnimateBox Image={AnimateImage3} title="Diseños especiales" subtitle="Hechos a la medida" />
                            </AnimateSliderBox>
                        </div>
                        <FeaturedSliderBox>
                            <FeaturedBox Image={FeaturedImage1} title="Diferentes diseños y tamaños" />
                            <FeaturedBox Image={FeaturedImage2} title="Da un regalo único" />
                        </FeaturedSliderBox>
                    </div>
                </div>
            </aside>
        )
    }
}