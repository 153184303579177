import React, {Component} from 'react';
import SocialIconsSection from '../molecules/SocialIconsSection';
import SocialIcon from '../atoms/SocialIcon';

export default class Footer extends Component {
    render () {
        return (
            <footer id="fh5co-footer" role="contentinfo">
                <div className="container">
                    <div className="row row-pb-md">
                        <div className="col-md-4 fh5co-widget">
                            <p className="fs-16">Permítenos transformar la esencia de tu compañero peludo en una pieza de arte atemporal. En LanuditoPet, convertimos tu amor por las mascotas en una experiencia artística que perdura para siempre.</p>
                            <br></br>
                            <p className="fst-italic">¡Gracias por confiar en nosotros para capturar la magia de tus adoradas mascotas!</p>
                        </div>
                        <div className="col-md-4 col-md-push-1 fh5co-widget">
                            <h4 className="fw-501">Links</h4>
                            <ul className="fh5co-footer-links">
                                <li><a href="/">Inicio</a></li>
                                <li><a href="/galeria">Galería</a></li>
                                <li><a href="/lugares">Lugares a los que hemos llegado</a></li>
                            </ul>
                        </div>

                        <div className="col-md-4 col-md-push-1">
                            <h4 className="fw-501">Contáctanos a través de estos medios</h4>
                            <ul className="fh5co-footer-links">
                                <li><strong>WhatsApp:</strong> 5530192251</li>
                                <li><strong>Correo:</strong> contacto@lanuditopet.com</li>
                                <li><strong>Instagram:</strong> <a href="https://www.instagram.com/lanuditopet?igshid=YTQwZjQ0NmI0OA==" target="blank_">@lanuditopet</a></li>
                                <li><strong>Facebook:</strong> <a href="https://www.facebook.com/LanuditoPet?mibextid=ZbWKwL" target="blank_">LanuditoPet</a></li>
                                <li><strong>Tiktok:</strong> <a href="https://www.tiktok.com/@lanuditopet?_t=8iBj9OGCToL&_r=1" target="blank_">@lanuditopet</a></li>
                            </ul>
                        </div>

                    </div>

                    <div className="row copyright">
                        <div className="col-md-12 text-center">
                            <p>
                                <small className="block">&copy; 2023 Lanuditopet. Todos los derechos reservados.</small> 
                            </p>
                            <p>
                                <SocialIconsSection>
                                    <SocialIcon className="bi bi-facebook" href="https://www.facebook.com/LanuditoPet?mibextid=ZbWKwL" />
                                    <SocialIcon className="bi bi-instagram" href="https://www.instagram.com/lanuditopet?igshid=YTQwZjQ0NmI0OA==" />
                                    <SocialIcon className="bi bi-tiktok" href="https://www.tiktok.com/@lanuditopet?_t=8iBj9OGCToL&_r=1" />
                                </SocialIconsSection>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}