import React from 'react';

type Props = {
    children?: React.Node
}

const SocialIconsSection = (props: Props) => {
    return (
        <ul className="fh5co-social-icons">
            {props.children}
        </ul>
    )
}

export default SocialIconsSection;