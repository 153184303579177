import React, {Component} from 'react';
import RowHeaderBox from '../molecules/RowHeaderBox';
import StoryBookDecorative from '../../images/storyBookDecorative.png';

export default class StoriesBooks extends Component {
    render () {
        return (
            <div id="fh5co-content" className="fh5co-no-pd-top">
                <div className="container">
                    <RowHeaderBox title="Cuentos" />
                </div>

                <iframe className="book-iframe" scrolling="yes"
                title="ratonVaquero"
                frameBorder="0"
                src='https://misitiodelgado.com/tstLb23/turnjs4lib/raton-vaquero.html'> 
                </iframe>
                
                <img className="story-book-decorative-text" src={StoryBookDecorative} alt="" />
                
                <iframe className="book-iframe" scrolling="yes"
                title="ratonVaquero"
                frameBorder="0"
                src='https://misitiodelgado.com/tstLb23/turnjs4lib/gato-botas.html'> 
                </iframe>
               
                <img className="story-book-decorative-text" src={StoryBookDecorative} alt="" />
                
                <iframe className="book-iframe" scrolling="yes"
                title="ratonVaquero"
                frameBorder="0"
                src='https://misitiodelgado.com/tstLb23/turnjs4lib/perro-shirley.html'> 
                </iframe>    
            </div>
        )
    }
}