import React from 'react';

type Props = {
    children?: React.Node
}

const AnimateSliderBox = (props: Props) => {
    return (
        <div className="flexslider animate-box fadeInUp animated-fast">
            <ul className="slides">
                {props.children}
            </ul>
        </div>
    );
}

export default AnimateSliderBox;