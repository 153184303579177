import React, {Component} from 'react';
import Header from '../templates/Header';
import Footer from '../templates/Footer';
import DeliveryMapZones from '../templates/DeliveryMapZones'

export default class DeliveryZones extends Component {
    componentDidMount () {
        window.executeHome();
    }

    render () {
        return (
            <div id="page">
                <Header activeOptionPosition="DeliveryZones" />
                <DeliveryMapZones />
                <Footer />
            </div>
        )
    }
}