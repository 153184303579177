import React from 'react';

const DesignBox = ({ Image, imageClassName, title }) => {
    return (
        <div className="col-md-3">
            <div className="fh5co-blog animate-box">
                <a><img className={imageClassName} src={Image} alt="" /></a>
                <div className="blog-text">
                    <h3><a>{title}</a></h3>
                </div> 
            </div>
        </div>
    )
}

export default DesignBox;