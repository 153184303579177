import React, {Component} from 'react';
import ImageMexicoMap from '../../images/DeliveryMaps/MexicoMap2.jpeg';
import ImageCanadaMap from '../../images/DeliveryMaps/CanadaMap.jpeg';
import ImageSpainMap from '../../images/DeliveryMaps/EspaniaMap.jpeg';
import ImagePeruMap from '../../images/DeliveryMaps/PeruMap.jpeg';

export default class DeliveryMapZones extends Component {
    render () {
        return (
            <div id="">
                <div className="container">
                    {/**<RowHeaderBox title="Lugares a los que hemos llegado" /> **/}
                    <div className='col-md-12 img-principal-display aling-items-center'>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            <img src={ImageMexicoMap} className="img-principal-autosize-adjust"/>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-3 col-lg-2 img-principal-lh'>
                            <p>Cdmx</p>
                            <p>Estado de Mexico</p>
                            <p>Querétaro</p>
                            <p>Aguascalientes</p>
                            <p>Baja California</p>
                            <p>Baja California sur</p>
                            <p>Jalisco</p>
                            <p>Chihuahua</p>
                            <p>Durango</p>
                            <p>Nuevo León</p>
                            <p>Colima</p>
                            <p>Michoacán</p>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-3 col-lg-2 img-principal-lh'>
                            <p>Guanajuato</p>
                            <p>Hidalgo</p>
                            <p>Oaxaca</p>
                            <p>Morelos</p>
                            <p>Puebla</p>
                            <p>Chiapas</p>
                            <p>Veracruz</p>
                            <p>Campeche</p>
                            <p>Quintana Roo</p>
                            <p>Yucatán</p>
                            <p>Guerrero</p>
                            <p>San Luis Potosí</p>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-12'>
                        <div className='col-xs-12 col-sm-4 col-md-4'>
                            <img src={ImageCanadaMap} className="img-autosize-adjust img-center"/>
                        </div>
                        <div className='col-xs-12 col-sm-4 col-md-4'>
                            <img src={ImageSpainMap} className="img-autosize-adjust img-center"/>
                        </div>
                        <div className='col-xs-12 col-sm-4 col-md-4'>
                            <img src={ImagePeruMap} className="img-autosize-adjust img-center"/>
                        </div>
                    </div>
                </div>
            </div>            
        )
    }
}