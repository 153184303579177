import React from 'react';
import MenuOption from './../atoms/MenuOption';
import SocialIconsSection from './../molecules/SocialIconsSection';
import SocialIcon from '../atoms/SocialIcon';

const Menu = ({ activeOptionPosition }) => {
    return (
        <div className="row">
            <div className="top-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7 text-left menu-1 left-menu-custom">
                            <ul>
                                <MenuOption text="Inicio" className={activeOptionPosition && activeOptionPosition == "Home" ? "active" : ""} href="/" />
                                <MenuOption text="Galería" className={activeOptionPosition && activeOptionPosition == "Gallery" ? "active" : ""} href="/galeria" />
                                <MenuOption text="Cuentos" className={activeOptionPosition && activeOptionPosition == "Stories" ? "active" : ""} href="/cuentos" />
                                <MenuOption text="Lugares a los que hemos llegado" className={activeOptionPosition && activeOptionPosition == "DeliveryZones" ? "active" : ""} href="/lugares" />
                            </ul>
                        </div>
                        <div className="col-sm-5 right-menu-custom">
                            <SocialIconsSection>
                                <SocialIcon className="bi bi-facebook" href="https://www.facebook.com/LanuditoPet?mibextid=ZbWKwL" />
                                <SocialIcon className="bi bi-instagram" href="https://www.instagram.com/lanuditopet?igshid=YTQwZjQ0NmI0OA==" />
                                <SocialIcon className="bi bi-tiktok" href="https://www.tiktok.com/@lanuditopet?_t=8iBj9OGCToL&_r=1" />
                            </SocialIconsSection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;