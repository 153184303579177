import React, {Component} from 'react';
import RowHeaderBox from '../molecules/RowHeaderBox';
import AboutUsStaffSection from '../molecules/AboutUsStaffSection';
import AboutUsImage from './../../images/LogoCircular.png';

export default class AboutUs extends Component {
    render () {
        return (
            <div id="fh5co-blog-popular">
                <div className="container">
                    <RowHeaderBox title="Acerca de nosotros" />
                    <AboutUsStaffSection Image={AboutUsImage} title="Lanuditopet" strongTitle="¡Bienvenidos! Desde el 2020, nos dedicamos con pasión a crear réplicas excepcionales de mascotas, utilizando la técnica del needle felting; cada una de estas creaciones son elaboradas meticulosamente a mano y utilizando materiales de la más alta calidad, es decir son totalmente artesanales." 
                    text="En LanuditoPet, capturamos la esencia única de tu mascota, convirtiéndola en una adorable réplica llena de personalidad y recuerdos. Nuestro enfoque artístico y detallado nos permite recrear a tu compañero peludo, plasmando su encanto y peculiaridad en cada fibra.
                    Nos enorgullecemos de ofrecer réplicas de alta calidad que reflejen la belleza y la esencia de tus mascotas; cada obra de needle felting es única, hecha con amor y dedicación. 
                    ¡Déjanos convertir a tu amigo peludo en una pieza única de arte que puedas atesorar por siempre!" />
                </div>
            </div>            
        )
    }
}