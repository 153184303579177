import React from 'react';

const AnimateBox = ({ Image, title, subtitle, text }) => {
    return (
        <li style={{ backgroundImage: "url(" + Image + ")"}}>
            <div className="overlay-gradient"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1 slider-text">
                        <div className="slider-text-inner">
                            <h1>{title}</h1>
                            <h2>{subtitle}</h2>
                            {/**<p className="ct">{text}</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default AnimateBox;